import React, { FunctionComponent } from 'react';
import Submenu from './Submenu';
import { icons } from '../../icons';
import { Wrapper, SimpleLink } from './styled';

const Links: FunctionComponent<{
  isMobile?: boolean;
  contrast?: boolean;
  links: LinksStrings[];
  navbarAlign?: string;
}> = ({ contrast = false, isMobile = false, links, navbarAlign }) => {
  return (
    <Wrapper isMobile={isMobile} contrast={contrast} navbarAlign={navbarAlign}>
      {links.map(({ key, id, link, sublinks = [], src }) => {
        const linkIcon = icons[src || ''];
        const hasSubmenu = sublinks.length > 0;
        if (!hasSubmenu) {
          return (
            <SimpleLink
              key={key}
              href={link}
              className="universal-menu-link"
              rel="canonical"
            >
              <span>{id}</span>
            </SimpleLink>
          );
        }
        return (
          <Submenu
            key={id}
            id={id}
            contrast={contrast}
            links={sublinks}
            isMobile={isMobile}
            linkIcon={linkIcon}
          />
        );
      })}
    </Wrapper>
  );
};

export default Links;
