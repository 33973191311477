import React, { FunctionComponent, useState } from 'react';
import ChevronDownSolid from '@bitcoin-portal/bitcoincom-pkg-components/dist/IconsNavigation/ChevronDownSolid';
import ChevronUpSolid from '@bitcoin-portal/bitcoincom-pkg-components/dist/IconsNavigation/ChevronUpSolid';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import { Sublink, SubmenuWrapper, Overlay } from './styled';
import { SubmenuHeader, Wrapper, Link } from '../styled';

const Links: FunctionComponent<{
  contrast?: boolean;
  links: LinksStrings[];
}> = ({ contrast = false, links }) => {
  const [currentSection, setCurrentSection] = useState('');
  const [close, setClose] = useState(true);

  const handleClick = (id: React.SetStateAction<string>) => {
    if (currentSection === id && close) {
      setClose(false);
      setCurrentSection('');
    } else if (currentSection === id && !close) {
      setCurrentSection('');
      setClose(true);
    } else {
      setCurrentSection(id);
      setClose(false);
    }
  };

  return (
    <Wrapper isMobile contrast={contrast}>
      {links.map(({ id, link, sublinks = [] }) => {
        if (link) {
          return (
            <Link
              key={id}
              href={link}
              className="universal-menu-link"
              rel="canonical"
              contrast={contrast}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {id}
              </span>
            </Link>
          );
        }
        return (
          <SubmenuHeader
            contrast={contrast}
            className="universal-menu-link"
            onClick={(): void => {
              handleClick(id);
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {id}
              {currentSection === id ? (
                <ChevronUpSolid
                  size={14}
                  color={contrast ? colors.solid.white : colors.solid.textOne}
                />
              ) : (
                <ChevronDownSolid
                  size={14}
                  color={contrast ? colors.solid.white : colors.solid.textOne}
                />
              )}
            </span>
            {currentSection === id && !close && (
              <>
                <Overlay onClick={() => handleClick(id)} />
                <SubmenuWrapper>
                  {sublinks.map(({ id: sublinkId, url }) => {
                    return (
                      <Sublink
                        key={sublinkId}
                        href={url}
                        contrast={contrast}
                        className="universal-menu-link sublink"
                        rel="canonical"
                      >
                        <span>{sublinkId}</span>
                      </Sublink>
                    );
                  })}
                </SubmenuWrapper>
              </>
            )}
          </SubmenuHeader>
        );
      })}
    </Wrapper>
  );
};

export default Links;
