import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';

import media from '../../media';

export const NavContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1920px;
  box-sizing: border-box;
  margin: auto;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  display: flex;
  align-items: center;

  & > * {
    line-height: normal !important;
  }

  ${media.md`
    font-size: 20px;
    margin: auto;
    padding: 0 70px;
  `}

  ${media.xl`
    padding: 0 100px;
  `}
`;

interface StickyWrapProps {
  sticky?: boolean;
  contrast?: boolean;
}

export const StickyNavWrap = styled.div<StickyWrapProps>`
  ${({ sticky, contrast }): FlattenSimpleInterpolation => css`
    z-index: 9999;
    /* transform: none !important; */
    text-align: center;
    height: 72px;
    padding: 12px 30px 8px;
    background: ${contrast ? colors.solid.vulcan : colors.solid.bgGrey};

    ${media.md`
      padding: 0;
    `}

    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;

    ${sticky &&
    css`
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 3px 6px 0 rgba(0, 0, 0, 0.06);

      ${media.md`
          right:0;
        `}
    `}
  `}
`;

export const OuterNavWrapper = styled.div<StickyWrapProps>`
  ${({ sticky, contrast }): FlattenSimpleInterpolation => css`
    margin: 0;
    opacity: 1 !important;

    .sticky-inner-wrapper {
      z-index: 9999;
    }
  `}
`;
