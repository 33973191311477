import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import media from '../../media';

export const Wrapper = styled.div<{ isMobile?: boolean }>`
  ${({ isMobile }): FlattenSimpleInterpolation => css`
    display: flex;
    box-sizing: border-box;

    ${media.xl`
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    `}

    ${isMobile &&
    css`
      display: flex;
      flex-flow: column-reverse nowrap;
      align-content: center;
      flex-wrap: wrap;
      width: 100%;
      justify-content: flex-start;

      ${media.md`
        justify-content: center;
        flex-flow: row nowrap;
        max-width: unset;
        align-items: flex-end;
      `}
    `}

    .universal-menu-link {
      font-size: 16px;
      text-decoration: none;

      & > div {
        padding: 12px 20px;
        height: 100%;
      }

      span {
        width: 100%;
      }
    }
  `}
`;

interface ButtonWrapperProps {
  isMobile?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  ${({ theme, isMobile = false }): FlattenSimpleInterpolation => css`
    box-sizing: border-box;
    position: relative;
    display: none;

    &:not(:last-child) {
      margin-right: ${theme.spacing.unit}px;
    }

    & > button, a {
      width: fit-content;
      max-width: unset;
    }

    ${media.xl`
      display: flex;

      .universal-menu-link {
        height: 40px;
        * {
          font-size: 14px;
        }
      }
    `}

    ${media.xl`
      .universal-menu-link {
        height: 48px;
        * {
          font-size: 16px;
        }
      }
    `}

    ${
      isMobile &&
      css`
        display: flex;
        margin: ${theme.spacing.unit}px;
        align-items: center;
        flex-direction: column;
        text-align: center;

        & > * + * {
          margin-top: 10px;
        }
      `
    }
  `}
`;

interface LinkProps {
  contrast?: boolean;
}

export const Link = styled.a<LinkProps>`
  ${({ theme, contrast }): FlattenSimpleInterpolation => css`
    position: relative;
    color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    cursor: pointer;
    margin: ${theme.spacing.unit}px;
    white-space: nowrap;
    height: 40px;
    ${media.lg`
      margin: 5px 40px 0 24px;
    `}
  `}
`;
