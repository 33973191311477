import React, { Component, PropsWithChildren } from 'react';
import { Wrapper, NavLogoWrapper, Container, ScrollContainer } from './styled';
import Logo from '../../Logo';
import { TimesSolid } from '../../Icons';
import MobileMenuButton from '../MobileMenuButton';
import Buttons from '../../Buttons';
import MobileLinks from '../../Links/MobileLinks';

interface MobileMenuProps {
  hideMobileMenu: () => void;
  links: LinksStrings[];
  buttons: ButtonsStrings[];
  contrast?: boolean;
}

interface MobileMenuState {
  height: string;
}

class MobileMenu extends Component<
  PropsWithChildren<MobileMenuProps>,
  MobileMenuState
> {
  constructor(props: MobileMenuProps) {
    super(props);
    this.state = {
      height: '1vh',
    };
  }

  componentDidMount(): void {
    const handleResize = (): void => {
      const height = window.innerHeight * 0.01;
      this.setState({ height: `${height}px` });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
  }

  render() {
    const { height } = this.state;
    const { hideMobileMenu, links, buttons, contrast } = this.props;

    return (
      <Wrapper height={height} contrast={contrast}>
        <Container>
          <NavLogoWrapper>
            <Logo contrast={contrast} />
            <MobileMenuButton
              menuOpened
              type="button"
              onClick={hideMobileMenu}
              contrast={contrast}
              aria-label="Close Menu"
            >
              <TimesSolid />
            </MobileMenuButton>
          </NavLogoWrapper>
          <ScrollContainer>
            <MobileLinks links={links} contrast={contrast} />
            <Buttons isMobile buttons={buttons} contrast={contrast} />
          </ScrollContainer>
        </Container>
      </Wrapper>
    );
  }
}

export default MobileMenu;
