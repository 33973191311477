import React, { FunctionComponent, ReactNode } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Anchor } from './styled';
import logoLight from '../../assets/logo_white.png';
import logoDark from '../../assets/logo_black.png';

interface LogoProps {
  contrast?: boolean;
  logo?: ReactNode;
  logoUrl?: string;
}

const Logo: FunctionComponent<LogoProps> = ({
  contrast = false,
  logo,
  logoUrl,
}) => (
  <Anchor href={logoUrl} className="universal-menu-link">
    {logo || (
      <LazyLoadImage
        src={contrast ? logoLight : logoDark}
        alt="Bitcoin.com logo"
        wrapperProps={{ style: { minHeight: 20, minWidth: 135 } }}
        effect="blur"
      />
    )}
  </Anchor>
);

export default Logo;
