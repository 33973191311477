import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import themes, { colors } from '@bitcoin-portal/bitcoincom-pkg-theme';
import media from '../../media';

interface SubmenuHeaderProps {
  contrast?: boolean;
}

export const SubmenuHeader = styled.div<SubmenuHeaderProps>`
  ${({ theme, contrast }): FlattenSimpleInterpolation => css`
    position: relative;
    color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
    background-color: ${
      contrast ? themes.dark.palette.background.default : colors.solid.bgGrey
    }};
    text-decoration: none;
    display: flex;
    align-content: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    cursor: pointer;
    margin: ${theme.spacing.unit}px 24px;
    white-space: nowrap;
  `}

  & > span {
    width: fit-content;
  }

  & svg {
    color: ${colors.solid.textOne};
    padding-left: 9px;
    margin: unset;
  }
`;
interface LinkProps {
  contrast?: boolean;
}

export const Link = styled.a<LinkProps>`
  ${({ theme, contrast }): FlattenSimpleInterpolation => css`
    position: relative;
    color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
    background-color: ${
      contrast ? themes.dark.palette.background.default : colors.solid.bgGrey
    }}

    text-decoration: none;
    display: flex;
    align-content: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    /* cursor: pointer; */
    margin: ${theme.spacing.unit}px 24px;
    white-space: nowrap;
  `}
`;

interface SimpleLinkProps {
  contrast?: boolean;
}

export const SimpleLink = styled.a<SimpleLinkProps>`
  ${({ theme, contrast }): FlattenSimpleInterpolation => css`
    position: relative;
    color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
    background-color: ${
  contrast ? themes.dark.palette.background.default : colors.solid.bgGrey
}};
    text-decoration: none;
    display: flex;
    align-content: center;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    cursor: pointer;
    margin: ${theme.spacing.unit}px 24px;
    white-space: nowrap;
  `}

  & > span {
    width: fit-content;
    padding: 6px 0;
  }
`;

interface WrapperProps {
  isMobile?: boolean;
  contrast: boolean;
  navbarAlign?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  display: none;

  ${({
    theme,
    isMobile = false,
    contrast,
    navbarAlign,
  }): FlattenSimpleInterpolation => css`
    ${media.xl`
      display: flex;
      width: 100%;
      gap: 32px;
      margin: 0px 40px;
      justify-content: ${(() => {
        if (navbarAlign && navbarAlign.toLowerCase() === 'left')
          return 'flex-start';
        if (navbarAlign && navbarAlign.toLowerCase() === 'right')
          return 'flex-end';
        if (navbarAlign && navbarAlign.toLowerCase() === 'center')
          return 'center';
        return 'flex-end';
      })()};
    `}

    ${isMobile &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      text-align: left;
      margin-left: 0;
      padding-bottom: ${theme.spacing.unit * 4}px;
      overflow-y: scroll;
      background-color: ${
        contrast ? themes.dark.palette.background.default : colors.solid.bgGrey
      }}

      & > ${Link}, ${SubmenuHeader} {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 32px;
        color: ${contrast ? colors.solid.bgWhite : colors.solid.textOne};
        display: block;
        margin: 16px 30px;
      }

      ${media.md`
        & > ${Link}, ${SubmenuHeader} {
          margin: 16px 70px;
        }
      `}
    `}
  `}
`;
