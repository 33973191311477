import 'regenerator-runtime';
import ReactDOM from 'react-dom';
import React from 'react';
import { StyleSheetManager } from 'styled-components';
import Navbar from './Navbar';

const el = document.getElementById('bitcoincom-wgt-navbar') || null;

if (el !== null) {
  const contrast = el.getAttribute('data-contrast') === 'true';

  // custom links
  const linksString = el.getAttribute('data-links');
  let buttons;
  let links;

  if (linksString && JSON.parse(linksString)) links = JSON.parse(linksString);

  // custom buttons
  const buttonsString = el.getAttribute('data-buttons');
  if (buttonsString && JSON.parse(buttonsString))
    buttons = JSON.parse(buttonsString);

  // set locale
  const locale = el.getAttribute('data-locale') || 'en';

  console.log('Universal navbar version', process.env.npm_package_version);

  ReactDOM.render(
    <StyleSheetManager target={el}>
      <Navbar
        ref={(component): void => {
          window.bitcoinComNavbar = component;
        }}
        contrast={contrast}
        isReact={false}
        links={links}
        buttons={buttons}
        locale={locale}
      />
    </StyleSheetManager>,
    el,
  );
}
