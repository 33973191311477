import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const InnerNavWrapper = styled.nav`
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: transparent;
  width: 100%;
  margin: auto;
  max-width: 1440px;
`;

export const NavLogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > a {
    display: flex;
  }

  ${media.xl`
    width: unset;
  `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
`;
