import React, { FunctionComponent, ReactNode } from 'react';
import { useLinks } from '../../hooks/useLinks';
import Buttons from '../Buttons';
import Links from '../Links';
import Logo from '../Logo';
import MobileNav from '../MobileNav';
import { Container, InnerNavWrapper, NavLogoWrapper } from './styled';

interface Props {
  contrast: boolean;
  links: LinksStrings[];
  buttons: ButtonsStrings[];
  logo?: ReactNode;
  logoUrl?: string;
  navbarAlign?: string;
}

const Content: FunctionComponent<Props> = ({
  logo,
  logoUrl,
  contrast,
  links,
  buttons,
  navbarAlign,
}) => {
  const allLinks = useLinks(links);

  return (
    <InnerNavWrapper>
      <Container>
        <NavLogoWrapper>
          <Logo logo={logo} contrast={contrast} logoUrl={logoUrl} />
          <MobileNav contrast={contrast} links={allLinks} buttons={buttons} />
        </NavLogoWrapper>
        <Links contrast={contrast} links={allLinks} navbarAlign={navbarAlign} />
      </Container>
      <Buttons contrast={contrast} buttons={buttons} />
    </InnerNavWrapper>
  );
};

export default Content;
