import styled from 'styled-components';
import media from '../../media';

export const Anchor = styled.a`
  & > span > img {
    height: 16px;
    width: 108px;
    object-fit: contain;
    vertical-align: middle;
    background-color: transparent !important;

    ${media.md`
      height: 20px;
      width: 135px;
      vertical-align: unset;
      margin-top: 2px;
    `}
  }
`;
